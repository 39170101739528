import React, { useEffect, useRef, useState } from "react";
import cv from "@techstark/opencv-js";
import { FaceLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsLeftRight, faDownload, faMoon, faVideoCamera, faWarning, faUpload } from "@fortawesome/free-solid-svg-icons";
import * as fr from "../translations/fr.json";
import * as de from "../translations/de.json";
import * as it from "../translations/it.json";
import * as es from "../translations/es.json";

window.cv = cv;

const PUPIL_TO_IRIS_RATIO = 0.30;
const IRIS_CIRCLE_CROP_FACTOR = 0.95;
// const IRIS_DARK_CONTOUR_THICKNESS = 0.7;
const IRIS_DARK_CONTOUR_THICKNESS_RATIO = 0.05;

let faceLandmarker;
let irisColor, irisColorLab;
let alpha;
let currDensity = null;
let currSelectedColor = null;
let cap, src, blendedImage;

// Calculate luminosity of an image
function calculateLuminosity(image) {
  const grayMat = new cv.Mat();
  cv.cvtColor(image, grayMat, cv.COLOR_RGB2GRAY);
  const mean = cv.mean(grayMat);
  grayMat.delete();
  return mean[0];
}

function lab2rgb(lab) {
  var y = (lab[0] + 16) / 116,
    x = lab[1] / 500 + y,
    z = y - lab[2] / 200,
    r, g, b;

  x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16 / 116) / 7.787);
  y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16 / 116) / 7.787);
  z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16 / 116) / 7.787);

  r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  b = x * 0.0557 + y * -0.2040 + z * 1.0570;

  r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1 / 2.4) - 0.055) : 12.92 * r;
  g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1 / 2.4) - 0.055) : 12.92 * g;
  b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1 / 2.4) - 0.055) : 12.92 * b;

  return [Math.max(0, Math.min(1, r)) * 255,
  Math.max(0, Math.min(1, g)) * 255,
  Math.max(0, Math.min(1, b)) * 255]
}

// Convert Lab color to RGB color with luminosity adjustment
function cvLabToRgb(labColor, luminosity, irisBrightness) {
  const labAdjusted = [...labColor];
  // Don't adjust these colors based on ambient luminosity
  // if (currSelectedColor == "TROPICAL_GREEN" || currSelectedColor == "OCEAN_BLUE" || currSelectedColor == "HONEY_GOLD") {
  // }

  // Adjust luminosity to prevent going too much in white tones (prevent shift for dark colors)
  let shift = +15;
  if (currSelectedColor == "TROPICAL_GREEN" || currSelectedColor == "OCEAN_BLUE") {
    shift = -30;
    // console.log("labAdjusted[0] -> ", labAdjusted[0]);
    labAdjusted[0] = Math.max(0, Math.min(100, labColor[0] + shift));
  } else if (currSelectedColor == "HONEY_GOLD") {
    shift = 0;
    const labLuminosity = Math.max(10, Math.min(80, (luminosity / 2.55) + shift));
    labAdjusted[0] = labLuminosity; // Set luminosity in the 0-100 range; max 80 / 100
  } else {
    const labLuminosity = Math.max(10, Math.min(80, (luminosity / 2.55) + shift));
    labAdjusted[0] = labLuminosity; // Set luminosity in the 0-100 range; max 80 / 100
  }

  // const labLuminosity = Math.max(10, Math.min(80, (luminosity / 2.55) + shift));
  // const labLuminosity = Math.max(10, Math.min(80, labColor[0] + luminosity/2.55 + shift));

  // labAdjusted[1] = 0.0250*irisBrightness - 4.850;
  // labAdjusted[2] = -0.01625*irisBrightness - 31.34;

  // console.log("labAdjusted -> ", labAdjusted);
  // console.log("lab2rgb(labAdjusted) -> ", lab2rgb(labAdjusted));

  return lab2rgb(labAdjusted);
}

function constrainBoundingRect(rect, imageWidth, imageHeight) {
  // Ensure the top-left corner of the rectangle is within the image boundaries
  rect.x = Math.max(rect.x, 0);
  rect.y = Math.max(rect.y, 0);

  // Ensure the bottom-right corner of the rectangle is within the image boundaries
  rect.width = Math.max(1, Math.min(rect.width, imageWidth - rect.x));
  rect.height = Math.max(1, Math.min(rect.height, imageHeight - rect.y));

  return rect;
}

export default function Simulator({ isBlurred }) {
  const canvasRef = useRef(null);
  const [language, setLanguage] = useState("en");
  const [selectedIrisColorName, setSelectedIrisColorName] = useState(null);
  const [density, setDensity] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const imageUploadRef = useRef(null);
  const imgRef = useRef(null); // Store the image

  const t = (str) => {
    switch (language) {
      case "fr":
        return fr[str];
      case "de":
        return de[str];
      case "it":
        return it[str];
      case "es":
        return es[str];
      default:
        return str;
    }
  };

  const handleColorChange = (newColor, newDensity) => {
    // Handle case is which only brightness is changed
    if (!newColor && !newDensity) {
      newColor = currSelectedColor;
      newDensity = currDensity;
    }

    if (newColor == selectedIrisColorName && newDensity == density) {
      irisColor = null;
      irisColorLab = null;
      setSelectedIrisColorName(null);
      return;
    }

    currDensity = newDensity;
    setDensity(newDensity);
    // if (newDensity == "LOW") {
    //   alpha = 0.5;
    // } else if (newDensity == "MEDIUM") {
    //   alpha = 0.5;
    // } else {
    //   alpha = 0.8;
    // }
    if (newDensity == "LOW") {
      alpha = 0.14;
    } else if (newDensity == "HIGH") {
      alpha = 0.30;
    } else { // MEDIUM
      alpha = 0.18;
      // alpha = 1;
    }

    currSelectedColor = newColor;
    setSelectedIrisColorName(newColor);
    
    if (newColor == "EMERALD_GREEN") {
      // irisColor = new cv.Scalar(116, 145, 93, 255); // RGB
      // irisColorLab = [57, -17, 25];
      if (newDensity == "LOW") {
        irisColorLab = [59, -17, 25];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [62, -18, 24];
      } else if (newDensity == "HIGH") {
        irisColorLab = [62, -18.6, 25.3];
      }
    } else if (newColor == "SNOW_WHITE") {
      // irisColor = new cv.Scalar(200, 200, 200, 255); // RGB
      // irisColorLab = [56, 0, 0];
      if (newDensity == "LOW") {
        irisColorLab = [59, -17, 25];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [62, -18.6, 25.3];
      }
    } else if (newColor == "HONEY_GOLD") {
      // irisColor = new cv.Scalar(123, 74, 44, 255); // RGB
      irisColorLab = [37, 19, 27];
    } else { // RIVIERA BLUE (default)
      // irisColor = new cv.Scalar(89, 136, 215, 255); // RGB
      irisColorLab = [56, 2, -46];
      if (newDensity == "LOW") {
        irisColorLab = [63, -3, -32];
      } else if (newDensity == "MEDIUM") {
        irisColorLab = [63, -2, -38];
      } else if (newDensity == "HIGH") {
        irisColorLab = [63, -1, -43];
      }
    }

    if (imgRef.current) {
      console.log("running detector on current image")
      runDetector(imgRef.current); // Re-run detector with the stored image
    }
  };

  // Initialize the object detector.
  const initializeObjectDetector = async () => {
    cleanupResources();

    const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
      },
      runningMode: "IMAGE", // "VIDEO"
      numFaces: 1
    });
  };

  // Function to cleanup resources
  const cleanupResources = () => {
    console.log("---");
    console.log("cleaning up");
    console.log("---");
    // Release OpenCV resources
    if (blendedImage) blendedImage.delete();
    if (src) src.delete();
    if (cap) cap.delete();
  };

  // Load iris color on page load
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setLanguage(queryParams.get("language") || "en");
    const queryParamColor = queryParams.get("color") || "RIVIERA_BLUE";
    handleColorChange(queryParamColor, "MEDIUM");
    initializeObjectDetector();

    // return cleanupResources;
  }, []);

  const handleUploadButtonClick = () => {
    imageUploadRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    img.onload = async () => {
      imgRef.current = img; // Store the image
      await runDetector(img);
    };
  };

  async function runDetector(img) {
    const inputCanvas = document.getElementById('input_canvas');
    inputCanvas.width = img.width;
    inputCanvas.height = img.height;
    const ctx = inputCanvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    const outputCanvas = document.getElementById('output_canvas');
    outputCanvas.width = img.width;
    outputCanvas.height = img.height;
    setLoaded(true);

    src = cv.imread(inputCanvas);
    blendedImage = new cv.Mat();

    let leftIrisMask, leftIrisContourMask, leftEyeRect;
    let rightIrisMask, rightIrisContourMask, rightEyeRect;

    // DETECT
    /////////
    // Ensure both operations complete (simultaneously) before proceeding
    const landmarkerResults = await faceLandmarker.detect(img);

    console.log("currSelectedColor -> ", currSelectedColor);
    console.log("irisColorLab -> ", irisColorLab);
    console.log("landmarkerResults -> ", landmarkerResults);

    // Landmarks application
    if (landmarkerResults?.faceLandmarks?.[0]) { // & irisColor
      const landmarks = landmarkerResults?.faceLandmarks?.[0];
      const LEFT_IRIS = [474, 475, 476, 477]
      const RIGHT_IRIS = [469, 470, 471, 472]
      const LEFT_EYE = [362, 382, 381, 380, 374, 373, 390, 249, 263, 466, 388, 387, 386, 385, 384, 398]
      const RIGHT_EYE = [33, 7, 163, 144, 145, 153, 154, 155, 133, 173, 157, 158, 159, 160, 161, 246]

      const leftIrisLandmarks = LEFT_IRIS.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
      const rightIrisLandmarks = RIGHT_IRIS.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
      const leftEyeLandmarks = LEFT_EYE.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);
      const rightEyeLandmarks = RIGHT_EYE.map(i => [landmarks[i].x * canvasRef.current.width, landmarks[i].y * canvasRef.current.height]);

      const promises = [];
      for (let i = 0; i < 2; i++) {
        const promise = new Promise(resolve => {
          setTimeout(() => {
            const irisCoordinates = (i === 0) ? leftIrisLandmarks : rightIrisLandmarks;
            const eyeCoordinates = (i === 0) ? leftEyeLandmarks : rightEyeLandmarks;

            // Extract eye ROI
            //////////////////
            // Get eye rounding rectangle
            const eyePtsMat1 = cv.matFromArray(eyeCoordinates.length, 1, cv.CV_32SC2, eyeCoordinates.flat());
            const eyeRectUnconstrained = cv.boundingRect(eyePtsMat1);
            eyeRectUnconstrained.y -= 5;
            eyeRectUnconstrained.height += 5;
            const eyeRect = constrainBoundingRect(eyeRectUnconstrained, src.cols, src.rows);
            const eyeROI = src.roi(eyeRect);

            const eyeCoordinatesROI = eyeCoordinates.map(coord => [
              coord[0] - eyeRect.x,
              coord[1] - eyeRect.y
            ]);
            const irisCoordinatesROI = irisCoordinates.map(coord => [
              coord[0] - eyeRect.x,
              coord[1] - eyeRect.y
            ]);

            // Process eye ROI
            //////////////////

            // Create a mask for the iris region
            const irisContour = new cv.MatVector();
            const irisMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
            irisContour.push_back(cv.matFromArray(irisCoordinatesROI.length, 1, cv.CV_32SC2, irisCoordinatesROI.flat()));
            cv.drawContours(irisMask, irisContour, 0, new cv.Scalar(255), cv.FILLED);
            irisContour.delete();
            irisMask.delete();

            // Compute minimum enclosing circle
            const irisPts = irisCoordinatesROI.map(coord => [coord[0], coord[1]]);
            const irisPtsMat = cv.matFromArray(irisPts.length, 1, cv.CV_32SC2, irisPts.flat());
            const irisMinEnclosingCircle = cv.minEnclosingCircle(irisPtsMat);
            irisPtsMat.delete();
            const circle = new cv.Point(irisMinEnclosingCircle.center.x, irisMinEnclosingCircle.center.y);
            const radius = irisMinEnclosingCircle.radius * IRIS_CIRCLE_CROP_FACTOR;

            // Create circular mask
            const circularMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
            cv.circle(circularMask, circle, radius, new cv.Scalar(255), cv.FILLED, cv.LINE_AA);
            // Darker contour mask around the iris
            const expandedContourMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
            // cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), radius * 0.02, cv.LINE_AA);
            // cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), IRIS_DARK_CONTOUR_THICKNESS, cv.LINE_AA);
            cv.circle(expandedContourMask, circle, radius, new cv.Scalar(255), radius * IRIS_DARK_CONTOUR_THICKNESS_RATIO, cv.LINE_AA);

            // Get eye rounding rectangle
            const eyePtsMat = cv.matFromArray(eyeCoordinatesROI.length, 1, cv.CV_32SC2, eyeCoordinatesROI.flat());
            // Draw eye mask
            const contoursVect = new cv.MatVector();
            contoursVect.push_back(eyePtsMat);
            const eyelidMask = new cv.Mat.zeros(eyeROI.rows, eyeROI.cols, cv.CV_8UC1);
            cv.drawContours(eyelidMask, contoursVect, 0, new cv.Scalar(255), cv.FILLED);
            eyePtsMat.delete();
            contoursVect.delete();

            // Convert eyeROI to grayscale & measure iris brightness
            // const eyeROIGray = new cv.Mat();
            // cv.cvtColor(eyeROI, eyeROIGray, cv.COLOR_RGBA2GRAY);
            // const irisInEyeROI = new cv.Mat();
            // eyeROIGray.copyTo(irisInEyeROI, circularMask);
            // const irisBrightness = cv.mean(irisInEyeROI)[0];
            // irisInEyeROI.delete();
            // eyeROIGray.delete();

            if (irisColorLab && i == 0) {
              // const luminosity = calculateLuminosity(eyeROI);
              // irisColor = new cv.Scalar(...cvLabToRgb(irisColorLab, luminosity));

              try {
                // const balanceRectZone = new cv.Rect(600, 200, 15, 15);
                // const leftRectX = Math.max(0, circle.x + radius + 6);
                // const leftRectY = Math.max(0, circle.y - 1);
                const balanceRectLeft = new cv.Rect(circle.x + radius + 7, circle.y - 1, 3, 3);
                
                // const rightRectX = Math.max(0, circle.x - radius - 6);
                // const rightRectY = Math.max(0, circle.y - 1);
                // const balanceRectRight = new cv.Rect(rightRectX, rightRectY, 3, 3);

                // const luminosityRight = calculateLuminosity(eyeROI.roi(balanceRectRight));
                const luminosityLeft = calculateLuminosity(eyeROI.roi(balanceRectLeft));
                // const luminosityZone = calculateLuminosity(src.roi(balanceRectZone));
                // console.log("luminosity -> ", luminosity / 2.55, "%");
                // const luminosity = (luminosityRight + luminosityLeft) / 2;
                const luminosity = luminosityLeft;
                // console.log("irisBrightness -> ", irisBrightness);
                irisColor = new cv.Scalar(...cvLabToRgb(irisColorLab, luminosity));
                
                // const rectColor = new cv.Scalar(255, 0, 0);
                // // cv.rectangle(
                // //   src,
                // //   {x: balanceRectZone.x, y: balanceRectZone.y},
                // //   {x: balanceRectZone.x + balanceRectZone.width, y: balanceRectZone.y + balanceRectZone.height},
                // //   rectColor,
                // //   2,
                // //   cv.LINE_AA
                // // );
                // cv.rectangle(
                //   eyeROI,
                //   {x: balanceRectLeft.x, y: balanceRectLeft.y},
                //   {x: balanceRectLeft.x + balanceRectLeft.width, y: balanceRectLeft.y + balanceRectLeft.height},
                //   rectColor,
                //   1,
                //   cv.LINE_AA
                // );
                // cv.rectangle(
                //   eyeROI,
                //   { x: balanceRectRight.x, y: balanceRectRight.y },
                //   { x: balanceRectRight.x + balanceRectRight.width, y: balanceRectRight.y + balanceRectRight.height },
                //   rectColor,
                //   1,
                //   cv.LINE_AA
                // );
              } catch(_) {
                // console.error(e);
                // Skip color adjustment if luminosity cannot be measured properly (e.g. out of eye's zone)
              }


              // cv.imshow("output_canvas_tmp", eyeROI);
            }

            // Exclude eyelid from circular mask
            cv.bitwise_and(circularMask, eyelidMask, circularMask);
            // Exclude eyelid from contour/border mask
            cv.bitwise_and(expandedContourMask, eyelidMask, expandedContourMask);

            eyelidMask.delete();

            // Free pupil
            cv.circle(circularMask, circle, radius * PUPIL_TO_IRIS_RATIO, new cv.Scalar(0), -1);

            if (i == 0) {
              leftIrisMask = circularMask;
              leftIrisContourMask = expandedContourMask;
              leftEyeRect = eyeRect;
            } else {
              rightIrisMask = circularMask;
              rightIrisContourMask = expandedContourMask;
              rightEyeRect = eyeRect;
            }

            resolve();
          }, 0); // Delay of 0 milliseconds to ensure asynchronous execution
        });
        promises.push(promise);
      }

      // Wait for all promises to resolve
      await Promise.all(promises);

      // Apply color to masks
      if (irisColor && irisColorLab) {
        src.copyTo(blendedImage);

        // For each iris, apply color and blend with source
        const leftColoredIris = new cv.Mat.ones(leftIrisMask.rows, leftIrisMask.cols, src.type());
        leftColoredIris.setTo(irisColor, leftIrisMask);
        leftColoredIris.setTo(new cv.Scalar(0, 0, 0, 255), leftIrisContourMask);
        // cv.GaussianBlur(leftColoredIris, leftColoredIris, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
        cv.blur(leftColoredIris, leftColoredIris, new cv.Size(3, 3));
        cv.addWeighted(src.roi(leftEyeRect), 1, leftColoredIris, alpha, 0, blendedImage.roi(leftEyeRect)); // Adjust alpha value as needed
        cv.blur(blendedImage.roi(leftEyeRect), blendedImage.roi(leftEyeRect), new cv.Size(1, 1));
        // cv.GaussianBlur(src.roi(leftEyeRect), src.roi(leftEyeRect), new cv.Size(15, 15), 0, 0, cv.BORDER_DEFAULT);
        leftIrisMask.delete(); // based on circularMask in iteration
        leftIrisContourMask.delete(); // based on circularMask in iteration
        leftColoredIris.delete();

        const rightColoredIris = new cv.Mat.ones(rightIrisMask.rows, rightIrisMask.cols, src.type());
        rightColoredIris.setTo(irisColor, rightIrisMask);
        rightColoredIris.setTo(new cv.Scalar(0, 0, 0, 255), rightIrisContourMask);
        // cv.GaussianBlur(rightColoredIris, rightColoredIris, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
        cv.blur(rightColoredIris, rightColoredIris, new cv.Size(3, 3));
        cv.addWeighted(src.roi(rightEyeRect), 1, rightColoredIris, alpha, 0, blendedImage.roi(rightEyeRect)); // Adjust alpha value as needed
        cv.blur(blendedImage.roi(rightEyeRect), blendedImage.roi(rightEyeRect), new cv.Size(1, 1));
        rightIrisMask.delete(); // based on expandedContourMask in iteration
        rightIrisContourMask.delete(); // based on expandedContourMask in iteration
        rightColoredIris.delete();

        // Display blended image
        cv.imshow(outputCanvas, blendedImage);

        // Release memory
        blendedImage.setTo(new cv.Scalar(0));
      } else {
        cv.imshow(outputCanvas, src);
      }
    } else {
      cv.imshow(outputCanvas, src);
    }

    src.delete();
  };

  const eyeColors = [
    {
      color: "rgb(89, 136, 215)",
      value: "RIVIERA_BLUE",
      label: "Riviera Blue",
    },
    {
      color: "rgb(116, 145, 93)",
      value: "EMERALD_GREEN",
      label: "Emerald Green",
    },
    {
      color: "rgb(157, 122, 83)",
      value: "HONEY_GOLD",
      label: "Honey Gold",
    },
  ];

  const [showInstructions, setShowInstructions] = useState(true);
  const displayInstructions = () => {
    setShowInstructions(true);
  };
  const dismissInstructions = () => {
    setShowInstructions(false);
  };
  const [showCredits, setShowCredits] = useState(false);
  const displayCredits = () => {
    setShowCredits(true);
  };
  const dismissCredits = () => {
    setShowCredits(false);
  };

  const handleDownloadPicture = () => {
    const colorToStr = {
      "RIVIERA_BLUE": "Riviera Blue",
      "EMERALD_GREEN": "Emerald Green",
      "HONEY_GOLD": "Honey Gold",
      "SNOW_WHITE": "Snow White",
    };
    const densityToStr = {
      "LOW": t("Low"),
      "MEDIUM": t("Medium"),
      "HIGH": t("High"),
    };

    // Assuming canvasRef.current is your original canvas
    const originalCanvas = canvasRef.current;

    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = originalCanvas.width;
    tempCanvas.height = originalCanvas.height;
    const tempContext = tempCanvas.getContext('2d');

    // Step 1: Copy original canvas to temporary canvas
    tempContext.drawImage(originalCanvas, 0, 0);

    // Step 2: Add text
    const fontSize = Math.ceil(0.025 * tempCanvas.height);
    tempContext.font = `${fontSize}px Arial`;
    tempContext.textAlign = 'center';
    tempContext.fillStyle = 'white';
    tempContext.strokeStyle = 'black';
    tempContext.lineWidth = 2;
    const textX = tempCanvas.width / 2;
    const textY = tempCanvas.height - 50;
    const textContent = `${colorToStr[selectedIrisColorName]} — ${t("Density:")} ${densityToStr[density]}`;
    tempContext.strokeText(textContent, textX, textY);
    tempContext.fillText(textContent, textX, textY);

    // // Load and draw the SVG image
    // const logoImage = new Image();
    // logoImage.onload = function() {
    //   // Adjust drawing settings as needed
    //   const logoWidthToHeightRatio = 2.68;
    //   const logoWidth = tempCanvas.height * 0.35;
    //   const logoHeight = logoWidth / logoWidthToHeightRatio;
    //   tempContext.drawImage(logoImage, tempCanvas.width / 2 - logoWidth / 2, tempCanvas.height - 80 - logoHeight - fontSize, logoWidth, logoHeight);

    //   // Export the canvas
    //   // ...
    // };
    // logoImage.src = '/BWVI_colorlogo.png';

    // Once everything is drawn, export the canvas
    const filename = `Helvy's Eye Recoloring Simulation - ${colorToStr[selectedIrisColorName]} - ${t("Density")} ${densityToStr[density]}`;
    if (navigator.canShare) {
      tempCanvas.toBlob(blob => {
        const fileName = `${filename}.png`; // Name of the file to share
        const file = new File([blob], fileName, {type: 'image/png'}); // Create a File object
        const filesArray = [file]; // Array of files to share
        navigator.share({
            files: filesArray,
            title: filename, // Title of the share
            // text: '', // Text to accompany the share
        })
        .then(() => console.log('Share was successful.'))
        .catch((error) => console.log('Sharing failed', error));
      }, 'image/png');
    } else {
        console.log(`Your system doesn't support sharing files.`);
        const image = tempCanvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.download = `${filename}.jpg`;
        link.href = image;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  };

  return (
    <>
      <div className="flash" />
      {!loaded && <h3>{t("Waiting for an image")}...</h3>}
      {!isBlurred && !showInstructions && <div className="overlay-logo">
        {/* <img src="/BWVI_colorlogo.png" /> */}
      </div>}
      <div style={{ zIndex: 100, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {!showInstructions && <div className="hud-instructions-toggle" onClick={() => displayInstructions()}>?</div>}
        {showInstructions &&
          <div className="hud-instructions" style={{ filter: isBlurred ? "blur(1rem)" : "none" }}>
            {showCredits && <>
              <h2 className="text-center text-xl font-bold mt-2 mb-4">Credits</h2>
              <p className="text-justify">
                <i>Eyes Recoloring Simulator by <a href="https://helvy.ch" target="_blank" className="text-white underline">Helvy</a></i> relies on state-of-the-art artificial intelligence technologies and computer vision algorithms to provide real-time simulation for eyes recoloring.
              </p>
              <button type="button" onClick={() => dismissCredits()}>Close</button>
            </>}
            {!showCredits && <>
              <h2 className="text-center text-xl font-bold mt-2 mb-4">{t("Welcome to Helvy's Eyes Recoloring Simulator!")}</h2>
              <p>{t("This simulator gives you a real-time overview how your eyes could look like with Helvy's Eyes Recoloring Colors.")}</p>
              <p className="my-4"><strong>{t("How to use it?")}</strong></p>
              <ul className="instructions-list">
                <li>Click on "Upload" below to upload a picture!</li>
              </ul>
              <button type="button" onClick={() => { dismissInstructions(); handleUploadButtonClick(); }}>Upload</button>
              <a href="#" className="credits-link" onClick={(e) => { e.preventDefault(); displayCredits() }}>Credits</a>
            </>}
          </div>
        }
        <div
          className="hud-messages"
        >
          <div id="low-light-warning" className="message" style={{ display: "none" }}>
            <FontAwesomeIcon icon={faMoon} /> <strong>{t("Low light")}</strong>
            <br />{t("Increase brightness and tap the camera icon to use the built-in flash; or try it in sunlight for the best results!")}
          </div>
          <div id="proximity-warning" className="message" style={{ display: "none" }}>
            <FontAwesomeIcon icon={faArrowsLeftRight} /> <strong>{t("Too close")}</strong>
            <br />{t("Stay ~40cm away from the camera for better results")}
          </div>
        </div>
        {!showInstructions && <>
          <div className="hud-take-picture">
            <button type="button" onClick={() => handleUploadButtonClick()}>
              <FontAwesomeIcon icon={faUpload} size="xl" />
            </button>
            <button type="button" onClick={() => handleDownloadPicture()}>
              <FontAwesomeIcon icon={faDownload} size="xl" />
            </button>
          </div>
          <div className="hud-bottom">
            <div className="colors-wrapper">
              <div className="left-col">
                {eyeColors.slice(0, 2).map((color) => (
                  <div
                    key={`color-${color.value}`}
                    className={`color-label ${selectedIrisColorName == color.value ? 'selected' : ''}`}
                    onClick={() => handleColorChange(color.value, density)}
                    aria-selected={selectedIrisColorName == color.value}
                  >
                    <div className="color-image-badge" style={{ border: `8px solid ${color.color}` }} />
                    <span>{color.label}</span>
                  </div>
                ))}
              </div>
              <div className="right-col">
                {eyeColors.slice(2, 4).map((color) => (
                  <div
                    key={`color-${color.value}`}
                    className={`color-label ${selectedIrisColorName == color.value ? 'selected' : ''}`}
                    onClick={() => handleColorChange(color.value, density)}
                    aria-selected={selectedIrisColorName == color.value}
                  >
                    <div className="color-image-badge" style={{ border: `8px solid ${color.color}` }} />
                    <span>{color.label}</span>
                  </div>
                ))}
                <div className="density-label">
                  <select value={density} onChange={(e) => handleColorChange(selectedIrisColorName, e.target.value)}>
                    <option value="LOW">{t("Density:")} {t("Low")}</option>
                    <option value="MEDIUM">{t("Density:")} {t("Medium")}</option>
                    <option value="HIGH">{t("Density:")} {t("High")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>}
        {/* <Webcam
          ref={webcamRef}
          onLoadedData={handleVideoLoad}
          style={{ position: "absolute", left: 0, top: 0, width: 0, height: 0, visibility: "hidden" }}
          videoConstraints={videoConstraints}
          screenshotFormat="image/jpeg"
        /> */}
        <canvas id="histo-output" style={{ position: "absolute", zIndex: 1000, height: "200px", width: "200px" }} />
        <canvas
          id="input_canvas"
          style={{ display: "none" }}
        />
        <canvas
          ref={canvasRef}
          className="output_canvas"
          id="output_canvas"
          style={{ display: "block", position: "absolute", zIndex: 100, top: 0, height: "100%", pointerEvents: "none", filter: isBlurred ? "blur(1rem)" : "none" }}
        ></canvas>
      </div>

      <div style={{ position: "relative" }}>
        <canvas
          className="output_canvas"
          id="output_canvas_tmp"
          style={{ display: "none", position: "relative", top: 0, left: 0, background: "grey" }}
        ></canvas>
      </div>
      <input
        type="file"
        ref={imageUploadRef}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
    </>
  );
}
